<template>
  <div v-if="hasResource('summary_report')">
    <overview-search class="mg-t-72"></overview-search>
    <overview-list></overview-list>
  </div>
</template>
<script>
import statsApi from "@/api/stats";
import { overviewSearch, overviewList } from "../components-index";
export default {
  components: {
    overviewSearch: overviewSearch,
    overviewList: overviewList,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>