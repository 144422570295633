<template>
  <div v-if="hasResource('detail_report')">
    <el-table
      :data="listData"
      style="width: 100%"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      v-loading="tableLoading"
      ref="balanceTable"
      class="data-table"
      @selection-change="handleSelectionChange"
      :default-sort="{ prop: 'datetime', order: 'descending' }"
      @sort-change="sortChange"
    >
      <el-table-column type="selection" width="125px"></el-table-column>

      <el-table-column
        label="时间"
        min-width="15%"
        show-overflow-tooltip
        prop="datetime"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span>{{ timeFormatted(scope.row.datetime) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="市场"
        prop="market"
        min-width="10%"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ market[scope.row.market] }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="用户邮箱"
        prop="customer_email"
        min-width="15%"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        label="用户名称"
        prop="customer_username"
        min-width="15%"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        label="外部ID"
        prop="asset_uid"
        min-width="15%"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        label="资产名称"
        prop="asset_name"
        min-width="15%"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="资产分类" min-width="15%" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.category.length === 0">--</span>
          <span v-for="(item, index) in scope.row.category" :key="index">
            {{ item.label }}
            <span
              v-if="
                scope.row.category.length > 1 &&
                scope.row.category.length-1 !== index
              "
              >,</span
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="积分"
        prop="amount"
        min-width="10%"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import statsApi from "@/api/stats";
import { EventBus } from "@/uitls/event-bus";
import { formatDate } from "@/uitls/date";

export default {
  data() {
    return {
      tableLoading: false,
      total: 0,
      listData: [],
      currentPage: 1,
      pageSize: 10,
      order_by: "created_at",
      sort: "DESC",
      filter: {},
      market: {
        US: "北美",
      },
    };
  },
  computed: {
    queryInfo() {
      const filterKeys = Object.keys(this.filter);
      const query = {
        limit: this.pageSize,
        page: this.currentPage,
        filter: this.filter,
        order_by: this.order_by,
        sort: this.sort,
      };
      return query;
    },
  },

  watch: {
    $route(to, from) {
      this.filter = to.query.filter
        ? JSON.parse(to.query.filter && to.query.filter)
        : {};
      this.currentPage = 1;
      this.updateList();
    },
  },
  methods: {
    updateList() {
      this.getBalanceDetail();
    },

    getBalanceDetail() {
      this.tableLoading = true;
      statsApi.getBalanceDetail(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          this.listData = res.data.data.items;
          this.total = JSON.parse(res.data.data.total);
        }
        this.tableLoading = false;
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.updateList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.updateList();
    },

    handleSelectionChange(val) {
      this.$emit("selectItems", val);
    },
    timeFormatted(time) {
      return time.split("-").join(".");
    },
    sortChange(column, prop, order) {
      if (column.prop == "datetime") {
        column.prop = "created_at";
      }
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.updateList();
    },
  },

  created() {
    EventBus.$on("makeFilter", (val) => {
      this.filter = val;
      this.currentPage = 1;
      this.updateList();
    });
  },
};
</script>