<template>
  <div class="dis-fix detail-search-content">
    <el-form ref="filterRef" :model="queryForm" :inline="true">
      <el-form-item label="资产名称" prop="asset_name">
        <el-input
          v-model="queryForm.asset_name"
          placeholder="输入名称查询"
          style="width: 168px"
        ></el-input>
      </el-form-item>
      <el-form-item label="外部ID" prop="asset_uid">
        <el-input
          v-model="queryForm.asset_uid"
          placeholder="输入外部ID"
          style="width: 168px"
        ></el-input>
      </el-form-item>
      <el-form-item label="资产分类" prop="category_id">
        <el-cascader
          v-model="category_id"
          :props="defaultParams"
          :options="categoryOptions"
          @change="handleChange"
          @remove-tag="removeTag"
          ref="cascaderAddr"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="时间" prop="created_at">
        <el-date-picker
          v-model="queryForm.created_at"
          type="daterange"
          range-separator="~"
          style="width: 168px"
          value-format="yyyy-MM-dd"
          format="yyyy.MM.dd"
          prefix-icon="none"
          :clearable="false"
          start-placeholder="请选择"
          end-placeholder="请选择"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="市场" prop="market">
        <el-select v-model="queryForm.market">
          <el-option
            v-for="(item, idx) in markets"
            :key="idx"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户邮箱" prop="customer_email">
        <el-input
          v-model="queryForm.customer_email"
          placeholder="输入邮箱查询"
          style="width: 168px"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名称" prop="customer_username">
        <el-input
          v-model="queryForm.customer_username"
          placeholder="输入名称查询"
          style="width: 168px"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="text-btn">
      <span class="serach-button" @click="send">查询</span>
      <span class="clear-button" @click="clearSearch">清空</span>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/uitls/event-bus";
import { formatDate } from "@/uitls/date";
import api from "../../../../api/assets";
export default {
  data() {
    return {
      queryForm: {
        created_at: [],
        market: "",
        customer_username_email: "",
        customer_username: "",
        customer_email: "",
        asset_uid: "",
        asset_name: "",
        category_id: [],
      },
      markets: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "北美",
          value: "US",
        },
      ],
      defaultParams: {
        label: "name",
        value: "id",
        children: "children",
        multiple: true,
      },
      categoryOptions: [],
      category_id: [],
      changeTrue: false,
    };
  },
  watch: {
    $route(to, from) {
      this.queryForm.created_at = [];
      const filter = to.query.filter
        ? JSON.parse(to.query.filter && to.query.filter)
        : {};
      this.queryForm.created_at[0] = to.query.filter
        ? filter.created_at.from
        : "";
      this.queryForm.created_at[1] = to.query.filter
        ? filter.created_at.to
        : "";
    },
  },
  computed: {
    defaultDates() {
      const curDate = new Date();
      const halfYear = (365 / 2) * 24 * 3600 * 1000;
      const pastRes = curDate.getTime() - halfYear;
      const pastDate = new Date(pastRes);
      const start = formatDate(pastDate, "yyyy-MM-dd");
      const end = formatDate(curDate, "yyyy-MM-dd");
      return [start, end];
    },
  },

  methods: {
    init() {
      const queryFilter = this.$route.query.filter;
      if (queryFilter) {
        let formattedFilter = JSON.parse(queryFilter);
        this.queryForm.created_at = [
          formattedFilter.created_at.from,
          formattedFilter.created_at.to,
        ];
      } else {
        this.queryForm.created_at = [];
      }
      this.send();
      this.category();
    },

    category() {
      api.categroy().then((res) => {
        if (res.data.code === 10200) {
          this.categoryOptions = res.data.data.children;
          // this.categoryOptions.unshift({ id: "", name: "全部" });
        }
      });
    },

    send() {
      const form = { ...this.queryForm };
      form.created_at = {
        from: this.queryForm.created_at[0]
          ? this.queryForm.created_at[0] + " 00:00:00"
          : null,
        to: this.queryForm.created_at[1]
          ? this.queryForm.created_at[1] + " 23:59:59"
          : null,
      };
      form.category_id =
        this.queryForm.category_id == []
          ? this.category_id
          : this.queryForm.category_id;
      EventBus.$emit("makeFilter", form);
    },
    handleChange(val) {
      if (this.changeTrue) {
        this.changeTrue = false;
        return;
      }
      this.queryForm.category_id = [];
      this.$refs.cascaderAddr.getCheckedNodes().forEach((item, index) => {
        if (item.checked == true) {
          this.queryForm.category_id.push(item.value);
        }
      });
      this.queryForm.category_id = Array.from(
        new Set(this.queryForm.category_id)
      );
    },
    removeTag(val) {
      this.changeTrue = true;
      this.$refs.cascaderAddr.getCheckedNodes().forEach((item, index) => {
        val.forEach((el) => {
          this.queryForm.category_id.forEach((e, index) => {
            if (el === e) {
              this.queryForm.category_id.splice(index, 1);
            }
          });
        });
      });
    },
    clearSearch() {
      this.clearForm("filterRef");
      this.$nextTick(() => {
        this.queryForm.created_at = [];
        this.category_id = [""];
      });
    },
  },

  mounted() {
    this.init();
  },
};
</script>
