<template>
  <div v-if="hasResource('summary_report')">
    <el-table
      :data="listData"
      style="width: 100%"
      class="data-table"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      v-loading="tableLoading"
      ref="balanceTable"
      :default-sort="{ prop: 'date', order: 'descending' }"
      @sort-change="sortChange"
    >
      <el-table-column
        label="汇总月份"
        min-width="20%"
        show-overflow-tooltip
        prop="date"
        sortable="custom"
      ></el-table-column>

      <el-table-column
        label="市场"
        prop="market"
        min-width="20%"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ market[scope.row.market] }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="积分总量"
        prop="subtotal"
        min-width="20%"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        label="使用次数"
        prop="count"
        min-width="20%"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column>
        <!-- 条目操作 -->
        <template slot="header">
          <div style="padding-left: 22px !important">操作</div>
        </template>
        <template slot-scope="scope">
          <div class="icon-btn">
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              v-if="hasResource('detail_report')"
              @click="handleView(scope.row)"
            >
              <i class="iconfont">&#xe662;</i> 查看
            </el-button>
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              v-if="
                hasResource('summary_report_export') &&
                hasResource('detail_report')
              "
              @click="handleExport(scope.row)"
              :loading="scope.row.btnLoading"
            >
              <i class="iconfont">&#xe65e;</i>导出明细
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import statsApi from "@/api/stats";
import { EventBus } from "@/uitls/event-bus";
import { formatDate } from "@/uitls/date";

export default {
  data() {
    return {
      tableLoading: false,
      total: 0,
      listData: [],
      currentPage: 1,
      pageSize: 10,
      order_by: "date",
      sort: "DESC",
      filter: {},
      market: {
        US: "北美",
      },
    };
  },
  computed: {
    queryInfo() {
      const filterKeys = Object.keys(this.filter);
      const query = {
        limit: this.pageSize,
        page: this.currentPage,
        filter: this.filter,
        order_by: this.order_by,
        sort: this.sort,
      };
      return query;
    },
  },

  methods: {
    updateList() {
      this.getBalanceOverview();
    },

    getBalanceOverview() {
      this.tableLoading = true;
      statsApi.getBalanceOverview(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          this.listData = res.data.data.items.map(item=>{
            item.btnLoading=false;
            return item
          });
          this.total = JSON.parse(res.data.data.total);
        } else {
          this.$$error(res.data.message);
        }
        this.tableLoading = false;
      });
    },

    viewJump() {
      const query = JSON.stringify(this.filter);
      this.$router.push({
        path: "/stats/balance-detail",
        query: {
          filter: query,
        },
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.updateList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.updateList();
    },

    handleView(row) {
      this.filter.created_at = this.formatTimeFrame(row);
      this.viewJump();
      this.filter.created_at = {};
    },

    handleExport(row) {
      this.filter.created_at = this.formatTimeFrame(row);
      this.exportRequest(row);
    },

    exportRequest(row) {
      row.btnLoading=true;
      statsApi.overviewExport(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          const url = res.data.data.export_url;
          this.downloadFile(url);
          this.$nextTick(() => {
            this.$$success("已导出");
          });
        }else {
          this.$$error(res.data.message);
        }
        row.btnLoading=false
      });
    },

    formatTimeFrame(row) {
      const dateInfo = row.date.replace("年", "-").replace("月", "").split("-");
      const start = new Date(dateInfo[0], dateInfo[1] - 1);
      const end = new Date(dateInfo[0], dateInfo[1], 0);

      const timeFrame = {
        from: formatDate(start, "yyyy-MM-dd"),
        to: formatDate(end, "yyyy-MM-dd"),
      };

      return timeFrame;
    },
    sortChange(column, prop, order) {
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.updateList();
    },
  },

  created() {
    EventBus.$on("makeFilters", (val) => {
      this.filter = val;
      this.currentPage = 1;
      this.updateList();
    });
  },
};
</script>