<template>
  <div>
    <div class="search-form">
      <div class="dis-fix">
        <el-form ref="filterRef" :model="queryForm" :inline="true">
          <el-form-item label="汇总月份" prop="created_at">
            <el-date-picker
              v-model="queryForm.created_at"
              type="monthrange"
              range-separator="~"
              style="width: 170px"
              value-format="yyyy-MM"
              format="yyyy.MM"
              prefix-icon="none"
              :clearable="false"
              start-placeholder="请选择"
              end-placeholder="请选择">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="市场" prop="market" >
            <el-select
              v-model="queryForm.market"
              style="width: 168px !important">
              <el-option
                v-for="(item, idx) in markets"
                :key="idx"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="text-btn">
          <span class="serach-button" @click="send">查询</span>
          <span class="clear-button"  @click="clearSearch">清空</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/uitls/event-bus";
import { formatDate } from "@/uitls/date";

export default {
  data() {
    return {
      queryForm: {
        created_at: [],
        market: "",
      },
      markets: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "北美",
          value: "US",
        },
      ],
    };
  },

  computed: {
    defaultMonth() {
      const curDate = new Date();
      const halfYear = (365 / 2) * 24 * 3600 * 1000;
      const pastRes = curDate.getTime() - halfYear;
      const pastDate = new Date(pastRes);

      const start = formatDate(pastDate, "yyyy-MM");
      const end = formatDate(curDate, "yyyy-MM");

      return [start, end];
    },
  },

  methods: {
    init() {
      this.queryForm.created_at = [];
      this.send();
    },

    send() {
      const form = { ...this.queryForm };
      const createdTime = this.queryForm.created_at;
      form.created_at = {
        from: createdTime[0],
        to: createdTime[1],
      };
      EventBus.$emit("makeFilters", form);
    },

    clearSearch() {
      this.clearForm("filterRef");
      this.$nextTick(() => {
        this.queryForm.created_at = [];
      });
    },
  },

  mounted() {
    this.init();
  },
};
</script>